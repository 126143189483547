import React, { Component } from 'react';
import Phone from "../images/phone.svg"
import { checkLocation} from '../helpers'

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                yourName: null,
                age: null,
                email: null,
                phone: null,
                location: null,
                quizScore: (typeof window !== `undefined` && window.sessionStorage.getItem('score')) ? parseInt(window.sessionStorage.getItem('score')) : 'Quiz not taken',
                conditions: (typeof window !== `undefined` && window.sessionStorage.getItem('conditions') ) ? window.sessionStorage.getItem('conditions') : 'Quiz not taken' ,
                I_do_things_slowly: (typeof window !== `undefined` && window.sessionStorage.getItem('q&a')) ? JSON.parse(window.sessionStorage.getItem('q&a')).I_do_things_slowly : 'Quiz not taken' ,
                My_future_seems_hopless: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).My_future_seems_hopless : 'Quiz not taken' ,
                It_is_hard_for_me_to_concentrate_on_reading: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).It_is_hard_for_me_to_concentrate_on_reading : 'Quiz not taken' ,
                I_have_difficulty_making_decisions: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_have_difficulty_making_decisions : 'Quiz not taken' ,
                I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me : null ,
                I_feel_sad_blue_and_unhappy: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_feel_sad_blue_and_unhappy : 'Quiz not taken' ,
                I_am_agitated_and_keep_moving_around: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_am_agitated_and_keep_moving_around : 'Quiz not taken' ,
                I_feel_fatigued: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_feel_fatigued: 'Quiz not taken' ,
                I_sleep_with_interruptions_too_much_or_too_little: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_sleep_with_interruptions_too_much_or_too_little : 'Quiz not taken' ,
                I_dont_feel_much_joy_even_when_good_things_happen_to_me: typeof window !== `undefined` && window.sessionStorage.getItem('q&a') ? JSON.parse(window.sessionStorage.getItem('q&a')).I_dont_feel_much_joy_even_when_good_things_happen_to_me : 'Quiz not taken' ,
                
            },
            errors: {
                yourName: 'Your Name is required!',
                age: 'Your Age is required!',
                email: 'Valid Email is required!',
                phone: 'Valid Phone is required!',
                location: checkLocation() ? '' : 'Please select a location!',
            },
            hasErrors: false,
        };
    }
    

    componentWillMount() {
        const { fields } = this.state
        this.setState(() => fields.location = checkLocation() || null)
    }

    handleChange = (event) => {
        event.preventDefault();
        const validEmailRegex = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/)
        const validPhoneRegex = new RegExp(/((\(\d{3}\)?)|(\d{3}))([\s-./]?)(\d{3})([\s-./]?)(\d{4})/)
        const { name, value } = event.target;
        let errors = this.state.errors;
        let fields = this.state.fields;
        switch (name) {
            case 'yourName':
                errors.yourName = value.length > 0 ? '' : 'Your Name is required!';
                fields.yourName = value;
                break;
            case 'age':
                errors.age = value.length > 0 ? '' : 'Your Age is required!';
                fields.age = value;
                break;
            case 'email':
                errors.email = (validEmailRegex.test(value) ? '' : 'Valid Email is required!');
                fields.email = value;
                break;
            case 'phone':
                errors.phone = (validPhoneRegex.test(value) ? '' : 'Valid Phone is required!');
                fields.phone = value;
                break;
            case 'message':
                fields.message = value;
                break;
            case 'location':
                fields.location = value;
                errors.location = value === 'Select an office location' && 'Please select a location!';
                break;
            default:
                break;
        }

        this.setState({ errors, fields, [name]: value });
    }

    handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm(this.state.errors)) {
            const FirstName = this.state.fields.yourName;
            if(typeof window !== `undefined`) {window.sessionStorage.setItem('name', FirstName)};
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({ "form-name": "Schedule a Consultation", ...this.state.fields })
            })
                .then(() => {
                    window.dataLayer && window.dataLayer.push({"event":"contactForm"});
                    if((window.location.pathname === '/consultation' || window.location.pathname === '/consultation/')){
                        window.location.pathname = "/thank-you" 
                    }else if(window.location.pathname === '/quiz-results-lp' ||window.location.pathname === '/quiz-results-lp/') {
                        window.location.pathname = "/quiz-consult-thank-you"
                    }else {
                        window.location.pathname = "/thanks"
                    }
                })
                .catch(error => alert(error));
        } else {
            this.setState({ hasErrors: true })
        }
    }

    render() {

        const { hasErrors, errors, fields } = this.state;
        const PhoneCta = () => <><img style={{ width: 35, marginRight: 9, paddingBottom: 5 }} src={Phone} alt='phone icon' /></>
        return (
            <div className="jumbotron jumbotron-fluid with-shadow">

                <div className='d-flex flex-column align-items-center' id="consultation">
                    <p className="is-purple font-weight-bold m-0 pb-1 font-size-20">Request a FREE  Ketamine Infusion Consultation</p>
                    {
                        typeof window !== `undefined` && (window.location.pathname === "/landing/" || window.location.pathname === "/landing") ?
                        <a className="is-purple font-weight-bold font-size-36 pb-1" href="tel:+18443263577"><PhoneCta />844.326.3577</a>
                        :
                        <a className="is-purple font-weight-bold font-size-36 pb-1" href="tel:+18446873646"><PhoneCta />844.687.3646</a>
                    }
                    <form name="Schedule a Consultation" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/success" onSubmit={this.handleSubmit} >
                        <input type="hidden" name="Schedule a Consultation" value="contact" />
                        <div className="container">
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="yourName">Your Name</label>
                                        <input id="name" className="p-2" placeholder="e.g. John Doe" type='text' name='yourName' onChange={this.handleChange} noValidate />
                                        {errors.yourName.length > 0 && hasErrors &&
                                            <span className='error'>{errors.yourName}</span>}
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="age">Your Age</label>
                                        <input className="p-2" placeholder="e.g. 35" type='text' name='age' onChange={this.handleChange} noValidate />
                                        {errors.age.length > 0 && hasErrors &&
                                            <span className='error'>{errors.age}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="email">Email</label>
                                        <input className="p-2" placeholder="e.g. john@email.com" type='email' name='email' onChange={this.handleChange} noValidate />
                                        {errors.email.length > 0 && hasErrors && errors.email &&
                                            <span className='error'>{errors.email}</span>}
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="phone">Phone</label>
                                        <input type='phone' name='phone' className="p-2" placeholder="e.g. 1231231234" onChange={this.handleChange} noValidate />
                                        {errors.phone.length > 0 && hasErrors && errors.phone && 
                                            <span className='error'>{errors.phone}</span>}
                                    </div>
                                </div>
                                <input type='text' name='quizScore' hidden />
                                <input type='text' name='conditions'  hidden />
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <label className="is-purple" htmlFor="location">Office Location</label>
                                    <select className="d-flex flex-column mb-2 is-purple p-2 select-styled" defaultValue={fields.location} name="location" onChange={this.handleChange} noValidate>
                                        { 
                                            fields.location &&
                                            <option className="is-purple p-2" value={fields.location} >{fields.location}</option>
                                        }
                                        {   
                                            fields.location === null &&
                                            <option className="is-purple p-2" value="Select an office location">Select your location</option>
                                        }
                                        {   
                                            fields.location !== "Las Vegas, NV" &&
                                            <option className="is-purple p-2" value="Las Vegas, NV">Las Vegas, NV</option>
                                        }
                                        {
                                            fields.location !== "Chicago, IL" &&
                                            <option className="is-purple p-2" value="Chicago, IL">Chicago, IL</option>
                                        }
                                        {
                                            fields.location !== "Phoenix, AZ" &&
                                            <option className="is-purple p-2" value="Phoenix, AZ">Phoenix, AZ</option>
                                        }
                                    </select>
                                    {errors.location.length > 0 && hasErrors && errors.location &&
                                            <span className='error'>{errors.location}</span>}
                                </div>
                                <input type='text' name='I_do_things_slowly' hidden />
                                <input type='text' name='My_future_seems_hopless'  hidden />
                                <input type='text' name='It_is_hard_for_me_to_concentrate_on_reading' hidden />
                                <input type='text' name='I_have_difficulty_making_decisions'  hidden />
                                <input type='text' name='I_have_lost_interest_in_aspects_of_my_life_that_used_to_be_important_to_me' hidden />
                                <input type='text' name='I_feel_sad_blue_and_unhappy'  hidden />
                                <input type='text' name='I_am_agitated_and_keep_moving_around' hidden />
                                <input type='text' name='I_feel_fatigued'  hidden />
                                <input type='text' name='I_sleep_with_interruptions_too_much_or_too_little' hidden />
                                <input type='text' name='I_dont_feel_much_joy_even_when_good_things_happen_to_me'  hidden />
                            </div>
                            <div className='row'>
                                <div className="col-sm">
                                    <div className="d-flex flex-column mb-2 is-purple">
                                        <label htmlFor="text">Additional Information</label>
                                        <textarea type='text' className="p-2" placeholder="Your message" name='message' onChange={this.handleChange} noValidate />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column mb-2 mt-3">
                                <button className="submit">Request a Consultation</button>
                            </div>
                            <p className="is-purple p-2 text-center font-size-14 info-privacy">Your information is 100% confidential and will never be shared with anyone</p>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}
const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}

export default ContactForm