import React from "react"
import SEO from "../components/seo"
import ContactForm from "../components/Form"
import Checkmark from "../images/checkmark.svg"
import QuizScoreComponent from '../components/quiz-score-component'
import Phone from "../images/phone.svg"

const PhoneCta = () => <><img style={{ width: 18, marginRight: 9, marginLeft:5, paddingBottom: 5 }} src={Phone} alt='phone icon' /></>

const CheckMark = () => <><img style={{ maxWidth: 30, height: 'auto', paddingBottom: '10px', marginRight: 10 }} src={Checkmark} alt='checkmark' /></>

const SecondPage = () => (
    <>
        <SEO title="Schedule an In-Office Consultation" />
        <div className="hero-wrapper consultation quiz-lp" >
            <div className="row consultation-wrapper align-items-start p-3 justify-content-center">
                <div className="usp-testimonials-wrapper quiz-lp col-sm-5">
                    <div className="bg-white p-3 usp-wrapper quiz-lp mb-3 with-shadow mt-3">
                        <QuizScoreComponent />
                        <table className="is-purple font-weight-normal">
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="pl-2 d-flex items-center">
                                            <CheckMark />
                                            <p className="pl-1">Rapid relief from depression, anxiety, PTSD, chronic pain and more</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="pl-2 d-flex items-center">
                                            <CheckMark />
                                            <p className="pl-1">Clinically proven to be 85% effective</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="pl-2 d-flex items-center">
                                            <CheckMark />
                                            <p className="pl-1">Symptom relief often after the first treatment</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="pl-2 d-flex items-center">
                                            <CheckMark />
                                            <p className="pl-1">All you need is 6 infusions over 14 days</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="is-purple font-weight-bold mt-3">
                            Isn't it time for you to reclaim your life and turned a new page? We are here to help. Call us at <a className="is-purple font-weight-bold pb-1" href="tel:+18446873646"><PhoneCta />(844) 687-3646</a> or fill out the
                            Consultation Request Form and a member of our medical team will call you to dicsuss your condition and schedule your in-office visit.
                        </p>
                    </div>
                </div>
                <div className='col-lg-5'>
                    <div style={{ maxWidth: 580, margin: 'auto' }}>
                        < ContactForm />
                    </div>

                </div>
                <div className="bg-white mt-2" style={{borderRadius: '13px', margin: 'auto'}}>
                    <h2 className="is-purple font-weight-bold m-0 p-2 text-center with-shadow">See what other patients have to say</h2>
                </div>
                
                <div className="container p-4">
                <div className="row pt-2">
                    <div className="col-12 col-lg-6 pt-2">
                        <div className="video-container">
                            <iframe className="video-frame with-shadow" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/3u0MaJCIWYk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="pt-2 col-12 col-lg-6">
                        <div className="video-container">
                            <iframe className="video-frame with-shadow" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/SZ3qvFEQb9w" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
                <div className="row pb-4">
                    <div className="col-12 col-lg-6 pt-4">
                        <div className="video-container">
                            <iframe className="video-frame with-shadow" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/J1z_RH3fMTU" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                    <div className="pt-4 col-12 col-lg-6">
                        <div className="video-container">
                            <iframe className="video-frame with-shadow" title="customer testimonial" width="100%" height="315" src="https://www.youtube.com/embed/nXatY_HUfhk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="overlay-white"></div>
        </div>
    </>
)

export default SecondPage
