import React, { Component } from "react"

class QuizScoreComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quizData: {
                score: typeof window !== `undefined` ? parseInt(window.sessionStorage.getItem('score')) : "Oops..something went wrong",
            },
            quizResults: {
                poor: `It looks like your neurological state is severely out of balance and it’s having a significant negative impact on your quality of life and overall health. There is hope: Ketamine Therapy can help. Ketamine Infusion Therapy is clinically proven to provide rapid relief for depression, anxiety, PTSD, chronic pain and more.`,
                fair: `It looks like your neurological state is getting progressively out of balance and it’s starting to have a noticeable negative effect on your everyday life. There is hope though! Ketamine Infusion Therapy can help. Ketamine Infusion Therapy is clinically proven to provide rapid relief for depression, anxiety, PTSD, chronic pain and more.`,
                moderate: `It looks like your neurological state may be getting out of balance and you are not feeling 100% these days.  There is hope though! Ketamine Infusion Therapy can help. Ketamine Infusion Therapy is clinically proven to provide rapid relief for depression, anxiety, PTSD, chronic pain and more.`,
                good: `It looks like your neurological state is balanced and you are likely more often than not in a good mood health. Congratulations! You may still find it useful to learn about what Ketamine Infusion Therapy can do for your overall mental health.`,
            }
        }
    }


    render() {
        const { quizData: { score }, quizResults } = this.state
        return (
            <div>
                <h2 className="is-purple font-weight-bold fot m-0 pb-4 text-center">You scored {score} of 100</h2>
                <div className="d-flex flex-column align-items-center m-0">

                    <p className="is-purple font-weight-bold m-0 pb-4" style={{textAlign: 'evenly', margin: '0 !important'}}>
                        {
                            score < 30 && quizResults.poor
                        }
                        {
                            score >= 30 && score <= 49 && quizResults.fair
                        }
                        {
                            score >= 49 && score <= 79 && quizResults.moderate
                        }
                        {
                            score >= 80 && quizResults.good
                        }
                    </p>
                </div>
            </div>
        )
    }
}

export default QuizScoreComponent
