export const getUrlParams = (search) => {
    let hashes = search.slice(search.indexOf('?') + 1).split('&')
    let params = {}
    // eslint-disable-next-line 
    hashes.map(hash => {
        let [key, val] = hash.split('=')
        params[key] = decodeURIComponent(val)
    })

    return params
}

export const checkLocation = () => {
    const location = (typeof window !== 'undefined' && getUrlParams(window.location.search).location) || null
    let value
    switch (location) {
        case 'vegas':
            value = 'Las Vegas, NV'
            break;
        case 'chicago':
            value = 'Chicago, IL'
            break;
        case 'phoenix':
            value = 'Phoenix, AZ'
            break;
        default:
            value = null
            break;
    }
    return value
}